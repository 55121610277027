import { useNavigate } from "react-router-dom";
import contactImgs from "../../assets/contact-us.png";

export default function CtaSection() {
  const navigate = useNavigate();
  function navigateToContact() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate("contact");
  }
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl py-24 sm:px-6 sm:py-28 lg:px-8">
        <div className="relative isolate overflow-hidden text-gray-900  bg-[#f9af3d] px-6 pt-16 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
          <svg
            viewBox="0 0 1024 1024"
            className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
            aria-hidden="true"
          >
            <circle
              cx={512}
              cy={512}
              r={512}
              fill="url(#759c1415-0410-454c-8f7c-9a820de03641)"
              fillOpacity="0.7"
            />
            <defs>
              <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                <stop stopColor="#ef453a" />
                <stop offset={1} stopColor="#f9af3d" />
              </radialGradient>
            </defs>
          </svg>
          <div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              Protect your digital world, with one click start using our
              services.
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-900">
              Our Services offers a comprehensive and proactive approach to
              Cybersecurity; we are committed to keeping your organization safe
              from Cyber threats and ensuring the confidentiality, integrity,
              and availability of your critical data and systems. We can also
              tailor our services to meet your specific requirements and help
              you achieve your cybersecurity goals.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
              <span
                onClick={navigateToContact}
                className="rounded-md cursor-pointer bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
              >
                Contact Us
              </span>
            </div>
          </div>
          <div className="relative mt-16 h-80 lg:mt-8">
            <img
              className="absolute left-0 top-0 w-[37rem] max-w-none rounded-md bg-white/5 ring-1 ring-white/10"
              src={contactImgs}
              alt="App screenshot"
              width={1824}
              height={1080}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
