import React, { useRef, useEffect } from "react";
import { BiRightArrowCircle } from "react-icons/bi";
import "./blog-1.css";

export default function Blog1() {
  const topRef = useRef(null);

  useEffect(() => {
    topRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  return (
    <>
      <div
        className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0"
        ref={topRef}
      >
        <div className="absolute inset-0 -z-10 overflow-hidden">
          <svg
            className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                width={200}
                height={200}
                x="50%"
                y={-1}
                patternUnits="userSpaceOnUse"
              >
                <path d="M100 200V.5M.5 .5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
              <path
                d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect
              width="100%"
              height="100%"
              strokeWidth={0}
              fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)"
            />
          </svg>
        </div>
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-1 lg:items-start lg:gap-y-10">
          <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-1 lg:gap-x-8 lg:px-8">
            <div className="lg:pr-4">
              <div className="">
                <p className="text-base font-semibold leading-7 text-indigo-600">
                  Threat Advisory
                </p>
                <h1 className="mt-2 text-3xl font-bold tracking-tight text-dark-900 sm:text-4xl">
                  MOVEit Transfer zero-day (CVE-2023-34362)
                </h1>

                <p className="mt-6 text-base leading-8 text-dark-700">
                  <b className="text-base font-semibold leading-7 text-dark-900">
                    Summary
                  </b>
                </p>
                <p className=" text-base leading-8 text-dark-700">
                  On May 31, 2023, Progress Software Corporation publicly
                  disclosed a critical SQL injection vulnerability
                  (CVE-2023-34362) in their MOVEit Transfer application. This
                  vulnerability poses a risk of unauthorized access to the
                  underlying database. Progress Software Corporation, a provider
                  of business applications, advises customers to promptly apply
                  patches and follow the mitigation measures outlined in their
                  security advisory. Microsoft has detected active exploitation
                  of these vulnerabilities by a cybercriminal group identified
                  as Lace Tempest (DEV-0950) since May 27, 2023.{" "}
                </p>
                <p className="mt-6 text-base leading-8 text-dark-700">
                  Lace Tempest is notorious for engaging in ransomware attacks,
                  extortion operations, and operating the Clop ransomware
                  extortion site. Exploiting the vulnerability allows the
                  deployment of a custom web shell with data exfiltration
                  capabilities on the compromised server. In some instances,
                  Lace Tempest has successfully exfiltrated files from MOVEit
                  Transfer servers. Historical cases involving similar
                  vulnerabilities exploited by Lace Tempest in other enterprise
                  file transfer software have resulted in stolen data being
                  extorted and posted on the Clop leak site.
                </p>
              </div>
            </div>
          </div>
          {/* <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
            <img
              className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
              src="https://tailwindui.com/img/component-images/dark-project-app-screenshot.png"
              alt=""
            />
          </div> */}
          <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-1 lg:gap-x-8 lg:px-8">
            <div className="lg:pr-4">
              <div className=" text-base leading-7 text-dark-700 ">
                <b className="text-base font-semibold leading-7 text-dark-900 ">
                  Impacted Technologies:
                </b>
                <ul role="list" className="mt-4 space-y-2 text-dark-600">
                  <li className="flex gap-x-3">
                    <BiRightArrowCircle
                      className="mt-1 h-5 w-5 flex-none text-indigo-600"
                      aria-hidden="true"
                    />
                    Users of MOVEit Transfer 2023.0.0 should install MOVEit
                    Transfer 2023.0.1.
                  </li>
                  <li className="flex gap-x-3">
                    <BiRightArrowCircle
                      className="mt-1 h-5 w-5 flex-none text-indigo-600"
                      aria-hidden="true"
                    />
                    Users of MOVEit Transfer 2022.1.x should install MOVEit
                    Transfer 2022.1.5.
                  </li>
                  <li className="flex gap-x-3">
                    <BiRightArrowCircle
                      className="mt-1 h-5 w-5 flex-none text-indigo-600"
                      aria-hidden="true"
                    />
                    Users of MOVEit Transfer 2022.0.x should install MOVEit
                    Transfer 2022.0.4.
                  </li>
                  <li className="flex gap-x-3">
                    <BiRightArrowCircle
                      className="mt-1 h-5 w-5 flex-none text-indigo-600"
                      aria-hidden="true"
                    />
                    Users of MOVEit Transfer 2021.1.x should install MOVEit
                    Transfer 2021.1.4.
                  </li>
                  <li className="flex gap-x-3">
                    <BiRightArrowCircle
                      className="mt-1 h-5 w-5 flex-none text-indigo-600"
                      aria-hidden="true"
                    />
                    Users of MOVEit Transfer 2021.0.x should install MOVEit
                    Transfer 2021.0.6.
                  </li>
                </ul>

                {/* <p className="mt-8">
                  Et vitae blandit facilisi magna lacus commodo. Vitae sapien
                  duis odio id et. Id blandit molestie auctor fermentum
                  dignissim. Lacus diam tincidunt ac cursus in vel. Mauris
                  varius vulputate et ultrices hac adipiscing egestas. Iaculis
                  convallis ac tempor et ut. Ac lorem vel integer orci.
                </p>
                <h2 className="mt-16 text-2xl font-bold tracking-tight text-dark-900">
                  No server? No problem.
                </h2>
                <p className="mt-6">
                  Id orci tellus laoreet id ac. Dolor, aenean leo, ac etiam
                  consequat in. Convallis arcu ipsum urna nibh. Pharetra,
                  euismod vitae interdum mauris enim, consequat vulputate nibh.
                  Maecenas pellentesque id sed tellus mauris, ultrices mauris.
                  Tincidunt enim cursus ridiculus mi. Pellentesque nam sed
                  nullam sed diam turpis ipsum eu a sed convallis diam.
                </p> */}
              </div>

              <div className=" text-base leading-7 text-dark-700 mt-8">
                <b className="text-base font-semibold leading-7 text-dark-900 ">
                  Threat Hunting:
                </b>

                <ul className="list-disc ml-4 nested_ul">
                  <li className=" mt-4">
                    Search for the malicious web shell specific to the Lace
                    Tempest activity running under the w3wp.exe process.
                    <ul className="list-disc ml-4">
                      <li>
                        The Initiating Process File Name field should contain
                        "w3wp.exe". This filters the events to those where the
                        initiating process is named "w3wp.exe".
                      </li>
                      <li>
                        The File Name field should contain "human2.aspx". This
                        filters the events to those where the file name includes
                        "human2.aspx".
                      </li>
                    </ul>
                  </li>
                  <li className=" mt-4">
                    Search for generic web shell creation after the release of
                    the exploit
                    <ul className="list-disc ml-4">
                      <li>
                        The Action Type should be "File Created". This filters
                        the events to those where a file was created.
                      </li>
                      <li>
                        The Folder Path should contain
                        "C:\MOVEitTransfer\wwwroot". This filters the events to
                        those where the folder path includes this specific
                        value.
                      </li>
                      <li>
                        The File Name should end with either "aspx" or "asp".
                        This further narrows down the events to those where the
                        file name ends with either of these extensions.
                      </li>
                    </ul>
                  </li>
                </ul>

                {/* <p className="mt-8">
                  Et vitae blandit facilisi magna lacus commodo. Vitae sapien
                  duis odio id et. Id blandit molestie auctor fermentum
                  dignissim. Lacus diam tincidunt ac cursus in vel. Mauris
                  varius vulputate et ultrices hac adipiscing egestas. Iaculis
                  convallis ac tempor et ut. Ac lorem vel integer orci.
                </p>
                <h2 className="mt-16 text-2xl font-bold tracking-tight text-dark-900">
                  No server? No problem.
                </h2>
                <p className="mt-6">
                  Id orci tellus laoreet id ac. Dolor, aenean leo, ac etiam
                  consequat in. Convallis arcu ipsum urna nibh. Pharetra,
                  euismod vitae interdum mauris enim, consequat vulputate nibh.
                  Maecenas pellentesque id sed tellus mauris, ultrices mauris.
                  Tincidunt enim cursus ridiculus mi. Pellentesque nam sed
                  nullam sed diam turpis ipsum eu a sed convallis diam.
                </p> */}
              </div>

              <div className="mt-4 text-base leading-7 text-dark-700 ">
                <b className="text-base font-semibold leading-7 text-dark-900 ">
                  Indicators of compromise:
                </b>
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-200">
                    <tbody>
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap">
                          162.244.34.26
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          162.244.35.6
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          179.60.150.143
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          185.104.194.156
                        </td>
                      </tr>
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap">
                          185.104.194.24
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          185.104.194.40
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          185.117.88.17
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          185.162.128.75
                        </td>
                      </tr>
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap">
                          185.174.100.215
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap">
                          185.174.100.250
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          185.181.229.240
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          185.181.229.73
                        </td>
                      </tr>
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap">
                          185.183.32.122
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          185.185.50.172
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          188.241.58.244
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          193.169.245.79
                        </td>
                      </tr>
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap">
                          194.33.40.103
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          194.33.40.104
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          194.33.40.164
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          206.221.182.106
                        </td>
                      </tr>
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap">
                          209.127.116.122
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          209.127.4.22
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          45.227.253.133
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          45.227.253.147
                        </td>
                      </tr>
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap">
                          45.227.253.50
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          45.227.253.6
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          45.227.253.82
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          45.56.165.248
                        </td>
                      </tr>
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap">
                          5.149.248.68
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          5.149.250.74
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          5.149.250.92
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          5.188.86.114
                        </td>
                      </tr>
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap">
                          5.188.86.250
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          5.188.87.194
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          5.188.87.226
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          5.188.87.27
                        </td>
                      </tr>
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap">
                          5.34.180.205
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          62.112.11.57
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          62.182.82.19
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          62.182.85.234
                        </td>
                      </tr>
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap">
                          66.85.26.215
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          66.85.26.234
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          66.85.26.248
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          79.141.160.78
                        </td>
                      </tr>
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap">
                          79.141.160.83
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          84.234.96.31
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          89.39.104.118
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          89.39.105.108
                        </td>
                      </tr>
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap">
                          91.202.4.76
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          91.222.174.95
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          91.229.76.187
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          93.190.142.131
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <h2 className="mt-16 text-base font-bold tracking-tight text-dark-900">
                  References
                </h2>
                <p className="mt-4">
                  <a
                    className="text-indigo-600"
                    href="https://community.progress.com/s/article/MOVEit-Transfer-Critical-Vulnerability-31May2023"
                    target="_blank"
                  >
                    https://community.progress.com/s/article/MOVEit-Transfer-Critical-Vulnerability-31May2023
                  </a>
                </p>
                <p>
                  <a
                    className="text-indigo-600"
                    href="https://www.cve.org/CVERecord?id=CVE-2023-34362"
                    target="_blank"
                  >
                    https://www.cve.org/CVERecord?id=CVE-2023-34362
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
