import React from "react";

const Map2 = () => {
  const iframeStyles = {
    width: "100%",
    height: "50vh", // Adjust the height as needed
    border: "none",
  };

  return (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14429.457787536008!2d55.365513!3d25.2919567!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5d2c4ddaa863%3A0xe75e8f38b9aef5e5!2siProtect%20Cybersecurity%20Services!5e0!3m2!1sen!2sin!4v1685904045887!5m2!1sen!2sin"
      allowFullScreen=""
      style={iframeStyles}
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
  );
};

export default Map2;
