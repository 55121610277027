import React from "react";

import { NavLink } from "react-router-dom";
import "./blogs.css";

import authorIcon from "../../assets/logo-icon.png";
import blogImgOne from "../../assets/blogs/moveit-transfer-zero-day-cve-2023-34362.png";
/* This example requires Tailwind CSS v2.0+ */
const posts = [
  {
    title: "MOVEit Transfer zero-day (CVE-2023-34362)",
    href: "/moveit-transfer-zero-day-cve-2023-34362",
    category: {
      name: "Threat Advisory",
      href: "/moveit-transfer-zero-day-cve-2023-34362",
    },
    description:
      "Progress Software disclosed a critical SQL injection vulnerability (CVE-2023-34362) in MOVEit Transfer, being actively exploited by the cybercriminal group Lace Tempest (DEV-0950). Immediate patching and mitigation are crucial to prevent unauthorized access, data exfiltration, and potential extortion.",
    date: "June 02, 2023",
    datetime: "2023-06-02",
    imageUrl: blogImgOne,
    readingTime: "6 min",
    author: {
      name: "iProtect CSS",
      href: "/moveit-transfer-zero-day-cve-2023-34362",
      imageUrl: authorIcon,
    },
  },
];

export default function Blogs() {
  return (
    <div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      <div className="absolute inset-0">
        <div className="bg-white h-1/3 sm:h-2/3" />
      </div>
      <div className="relative max-w-7xl mx-auto">
        <div className="">
          <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl text-center">
            Enhance Your Security with Actionable Threat Intelligence
          </h2>
          <p className="mt-3  mx-auto text-xl text-gray-500 sm:mt-4">
            Our advanced threat intelligence solutions keep you informed about
            emerging cyber threats, vulnerabilities, and attack vectors. Gain
            real-time insights, proactively mitigate risks, and fortify your
            defenses with our expert analysis. Protect your digital environment
            effectively with our comprehensive threat intelligence services.
          </p>
        </div>
        <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          {posts.map((post) => (
            <div
              key={post.title}
              className="flex flex-col rounded-lg shadow-lg overflow-hidden"
            >
              <div className="flex-shrink-0">
                <img
                  className="w-full object-cover"
                  src={post.imageUrl}
                  alt=""
                />
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-sm font-medium text-indigo-600">
                    <NavLink
                      href={post.category.href}
                      className="hover:underline"
                    >
                      {post.category.name}
                    </NavLink>
                  </p>
                  <NavLink href={post.href} className="block mt-2">
                    <p className="text-xl font-semibold text-gray-900">
                      {post.title}
                    </p>
                    <p className="mt-3 text-base text-gray-500">
                      {post.description}
                    </p>
                  </NavLink>
                </div>
                <div className="mt-6 flex items-center">
                  <div className="flex-shrink-0">
                    <NavLink href={post.author.href}>
                      <span className="sr-only">{post.author.name}</span>
                      <img
                        className="h-10 w-10 rounded-full"
                        src={post.author.imageUrl}
                        alt=""
                      />
                    </NavLink>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-gray-900">
                      <NavLink
                        href={post.author.href}
                        className="hover:underline"
                      >
                        {post.author.name}
                      </NavLink>
                    </p>
                    <div className="flex space-x-1 text-sm text-gray-500">
                      <time dateTime={post.datetime}>{post.date}</time>
                      <span aria-hidden="true">&middot;</span>
                      <span>{post.readingTime} read</span>
                    </div>
                  </div>
                </div>
                <div className="text-sm text-gray-500">
                  <NavLink to={post.href} className="readmore">
                    Read More
                  </NavLink>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
